import React, { useEffect, useState, useRef } from 'react'
import { List } from 'devextreme-react/list'
import axios from 'axios'
import notify from 'devextreme/ui/notify'
import { UploadDialog, TimestampDisplay } from '../../components'
import { Button as FormButton } from 'devextreme-react/button'
import { useLocation } from 'react-router-dom'
import { socketGet } from '../../socket'
import { saveAs } from 'file-saver'
import DataGrid, { Column, HeaderFilter, Selection, Paging, Button } from 'devextreme-react/data-grid'
import { useAuth } from '../../contexts/auth'
interface Warning {
	text: string
}

export default function Imports() {
	const [items, setItems] = useState<any>([])
	const [users, setUsers] = useState<any>({})
	const [warnings, setWarnings] = useState<Warning[]>([])
	const [text, setText] = useState('')
	const [showDialog, setShowDialog] = useState(false)
	const [refresh, setRefresh] = useState(0)
	const [successMessage, setSuccessMessage] = useState<boolean>(false)

	const gridRef = useRef<DataGrid | null>(null)

	const location = useLocation()
	const searchParams = new URLSearchParams(location.search)
	const process = searchParams.get('process')
	const socket = socketGet()

	const { user } = useAuth()

	useEffect(() => {
		const handlerWarning = (data: any) => {
			const { warning } = data
			const { message, line } = warning
			const text = line > 0 ? `${message} on line ${line}` : message
			setWarnings(warnings => [...warnings, { text }])
		}

		if (socket) {
			socket.on('legacy_import_warning', handlerWarning)

			return () => {
				socket.off('legacy_import_warning', handlerWarning)
			}
		}
	}, [socket, warnings])

	useEffect(() => {
		axios
			.get(`${API_FIBER_URL}/imports?process=${process}`)
			.then(response => response.data)
			.then(data => {
				setItems(data.list)
				setUsers(data.users)
				setText(data.text)
				document.title = `Fiber :: ${data.text}`
			})
	}, [process, refresh])

	const handleClick = (e: any) => {
		const hash = e.row.data.hash
		axios({
			method: 'get',
			url: `${API_FIBER_URL}/imports/file/${encodeURIComponent(hash)}/download?process=${process}`,
			responseType: 'blob',
		}).then(response => {
			const { data, headers } = response
			const filename = atob(hash)
			const blob = new Blob([data], { type: headers['content-type'] })
			saveAs(blob, filename)
		})
	}

	return (
		<React.Fragment>
			<h2 className={'content-block'}>
				<span className={'dx-icon-upload'} style={{ fontSize: '24px' }}></span> {text}
			</h2>
			<div className={'content-block'}>
				<FormButton
					icon="upload"
					text="Import new file"
					width={'400px'}
					height={'100px'}
					onClick={() => {
						setShowDialog(true)
					}}
				/>
			</div>
			<div className={'content-block'}>
				{successMessage ? <p style={{ fontSize: '18px', color: 'green' }}>File uploaded successfully</p> : null}
			</div>
			<br />
			{warnings.length > 0 && (
				<div className={'content-block'}>
					<h2>Warnings:</h2>
					<List className={'warningList'} items={warnings} displayExpr="text" />
				</div>
			)}
			<div className={'content-block'}>
				<h2>Imported files:</h2>
				<DataGrid ref={gridRef} dataSource={items} rowAlternationEnabled={true} showBorders={true} width="100%">
					<Paging defaultPageSize={15} defaultPageIndex={0} />
					<Selection mode="single" />
					<Column dataField="file" />
					<Column dataField="rows" width="100" />
					<Column
						dataField="date"
						width="120"
						cellRender={({ data }) => {
							return <TimestampDisplay timestamp={data['time']}></TimestampDisplay>
						}}
					/>
					<Column
						dataField="user"
						width="150"
						cellRender={({ data }) => {
							const email = (users[data['user']] ?? '').split('@')[0]
							return user?.email === email ? <strong>{email}</strong> : email
						}}
					/>
					<Column type="buttons" width="40">
						<Button icon="download" hint="download" onClick={handleClick} />
					</Column>
				</DataGrid>
				<HeaderFilter visible={true} />
			</div>
			{showDialog && (
				<UploadDialog
					onHide={() => setShowDialog(false)}
					onComplete={() => {
						setShowDialog(false)
						notify({ message: 'File uploaded successfully' }, 'success', 5000)
						setSuccessMessage(true)
						setRefresh(refresh + 1)
					}}
					onRefresh={() => {
						setRefresh(refresh + 1)
					}}
					onErrorFields={() => {
						notify({ message: 'Error uploading file, columns missing' }, 'error', 5000)
					}}
				/>
			)}
		</React.Fragment>
	)
}
